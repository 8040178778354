import js.core.jso
import react.router.dom.createBrowserRouter
import ui.screen.categorylist.CategoryListScreen
import ui.screen.contact.ContactScreen
import ui.screen.home.HomeScreen
import ui.screen.notfound.NotFoundScreen
import ui.screen.privacy.PrivacyScreen
import ui.screen.terms.TermsScreen

val appRouter = createBrowserRouter(
    routes = arrayOf(
        jso {
            path = "/"
            Component = HomeScreen
        },
        jso {
            path = "/privacy"
            Component = PrivacyScreen
        },
        jso {
            path = "/terms"
            Component = TermsScreen
        },
        jso {
            path = "/contact"
            Component = ContactScreen
        },
        jso {
            path = "/category/:categoryId"
            Component = CategoryListScreen
        },
        jso {
            path = "*"
            Component = NotFoundScreen
        }
    )
)
