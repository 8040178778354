package ui.component

import mui.material.*
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.PropsWithChildren
import ui.theme.AppTheme
import web.cssom.Color

external interface AppLayoutProps : Props, PropsWithChildren {
    var title: String
}

val AppLayout = FC<AppLayoutProps> { props ->
    val darkMode = useMediaQuery("(prefers-color-scheme: dark)")
    val appTheme = if (darkMode) AppTheme.Dark else AppTheme.Light
    ThemeProvider {
        theme = appTheme
        CssBaseline()
        AppBar {
            position = AppBarPosition.fixed
            Toolbar {
                Typography {
                    variant = TypographyVariant.h6
                    +props.title
                    sx {
                        color = appTheme.palette.text.primary
                    }
                }
                sx {
                    backgroundColor = Color(appTheme.palette.background.default)
                }
            }
        }
        Toolbar {}
        +props.children
    }
}
