package com.corradodev.dothings.util

import com.corradodev.dothings.data.Category

internal object CategoryUtil {
    val categories = listOf(
        Category(
            id = "adventure_exploration",
            name = "Adventure and Exploration",
            categories = listOf(
                Category(
                    id = "hiking",
                    name = "Hiking",
                    description = "Exploring trails",
                    descriptionLong = "Hiking is an invigorating way to explore nature, improve fitness, and find peace away from the city's hustle. Trails range from easy walks to challenging climbs, offering something for every skill level. Along the way, you might discover hidden waterfalls, breathtaking views, and diverse wildlife. Hiking is not just a physical activity; it's a journey of discovery and connection with nature."
                ),
                Category(
                    id = "camping",
                    name = "Camping",
                    description = "Outdoor camping experience",
                    descriptionLong = "Camping offers a unique chance to unwind and bond with friends and family. Whether it’s setting up tents, cooking over an open fire, or stargazing, camping brings simple pleasures and a break from technology. It's an opportunity to learn survival skills, appreciate the quiet of the outdoors, and create memories that last a lifetime."
                ),
                Category(
                    id = "scuba_diving",
                    name = "Scuba Diving",
                    description = "Underwater diving adventure",
                    descriptionLong = "Scuba diving opens the door to an extraordinary world beneath the waves. It's a chance to explore coral reefs, observe fascinating marine life, and experience the tranquility of being underwater. Diving courses are available for beginners, ensuring a safe and guided introduction to this awe-inspiring activity."
                ),
                Category(
                    id = "backpacking",
                    name = "Backpacking",
                    description = "Backpacking through diverse terrains",
                    descriptionLong = "Backpacking is the ultimate adventure for those who seek to explore various landscapes on foot. It challenges your limits and rewards you with experiences of different cultures, climates, and terrains. From the rugged mountains to the serene forests, and the vast deserts, backpacking is about the journey as much as the destination."
                ),
                Category(
                    id = "safari_tours",
                    name = "Safari Tours",
                    description = "Wildlife safari tours",
                    descriptionLong = "Safari wildlife tours offer a thrilling opportunity to observe wildlife in their natural environment. Safaris educate about conservation while providing close encounters with majestic animals. It's not just about seeing wildlife; it's about understanding the ecosystem and the importance of preserving it for future generations."
                )
            )
        ),
        Category(
            id = "arts_creativity",
            name = "Arts and Creativity",
            categories = listOf(
                Category(
                    id = "painting",
                    name = "Painting",
                    description = "Creating art through painting",
                    descriptionLong = "Painting offers a creative outlet to express emotions and thoughts visually. It's an activity where you can immerse yourself in colors, textures, and techniques, whether you're a beginner or an experienced artist. Art classes provide guidance and inspiration, while personal projects allow for self-expression and relaxation. From watercolors to acrylics, each medium offers a different experience. Painting fosters creativity, reduces stress, and gives a sense of accomplishment."
                ),
                Category(
                    id = "sculpting",
                    name = "Sculpting",
                    description = "Crafting sculptures",
                    descriptionLong = "Sculpting is an art form where materials like clay, stone, or metal are shaped and carved to create three-dimensional artworks. It's a blend of creativity and physical skill, using tools like chisels and mallets. Sculptors express emotions and narratives through their art, experiencing a meditative connection with the material. This activity ranges from small pieces to large public artworks and is both artistically fulfilling and therapeutic. Sculpting nurtures creativity, patience, and technical prowess, making it a deeply enriching pursuit for artists of all levels."
                ),
                Category(
                    id = "photography",
                    name = "Photography",
                    description = "Capturing moments",
                    descriptionLong = "Photography is about capturing moments and turning them into memories. It's both an art form and a technical skill that allows you to see the world through a different lens. From landscapes and cityscapes to portraits and candid shots, photography teaches you to observe and appreciate the beauty around you. Whether you use a camera or a smartphone, photography is accessible to everyone. It enhances your perspective, encourages exploration, and allows for creative expression."
                ),
                Category(
                    id = "diy_crafts",
                    name = "DIY Crafts",
                    description = "Creating handmade crafts",
                    descriptionLong = "Engaging in DIY crafts and creating handmade items is a rewarding and often therapeutic activity. It encompasses a wide range of crafts, including knitting, scrapbooking, jewelry making, and more. This hobby not only sparks creativity but also provides the satisfaction of creating something tangible. It's perfect for all ages and skill levels, offering endless possibilities to learn new techniques and make personalized items. Crafting can be a social activity, a form of self-expression, and a way to unwind."
                ),
                Category(
                    id = "writing_poetry",
                    name = "Writing Poetry",
                    description = "Composing poems",
                    descriptionLong = "Writing poetry is a profound way to express emotions, experiences, and observations in a structured yet creative form. It allows you to play with language, rhythm, and imagery to convey feelings and stories. Poetry writing can be introspective, helping to process thoughts and emotions, or it can be a way to connect with others by sharing your work. Whether you're jotting down verses in a notebook or attending poetry workshops, the art of composing poems is a deeply personal and rewarding creative outlet."
                )
            )
        ),
//        Category(
//            id = "culinary_experiences",
//            name = "Culinary Experiences",
//            categories = listOf(
//                Category(
//                    id = "cooking_classes",
//                    name = "Cooking Classes",
//                    description = "Learn new cooking techniques"
//                ),
//                Category(
//                    id = "wine_tasting",
//                    name = "Wine Tasting",
//                    description = "Explore various wine flavors"
//                ),
//                Category(
//                    id = "food_festivals",
//                    name = "Food Festivals",
//                    description = "Experience food festivals"
//                ),
//                Category(id = "baking", name = "Baking", description = "Bake different recipes"),
//                Category(id = "mixology", name = "Mixology", description = "Craft unique cocktails")
//            )
//        ),
        Category(
            id = "entertainment_leisure",
            name = "Entertainment and Leisure",
            categories = listOf(
                Category(
                    id = "movie_nights",
                    name = "Movie Nights",
                    description = "Enjoy movies with friends or family",
                    descriptionLong = "Hosting or attending movie nights is a popular social activity that brings friends and family together for entertainment and relaxation. It's about creating a cozy atmosphere at home, choosing films that appeal to the group, and enjoying the shared experience of watching a movie. Accompanied by snacks like popcorn, movie nights are a simple yet enjoyable way to bond and unwind. Whether it's a blockbuster, a classic, or a new release, movie nights are a staple of home entertainment."
                ),
                Category(
                    id = "concerts",
                    name = "Concerts",
                    description = "Attend live music concerts",
                    descriptionLong = "Attending concerts and live music events is an exhilarating experience that connects you with artists and fellow music enthusiasts. The energy of a live performance, the sound of music filling the venue, and the collective excitement of the audience create an unforgettable atmosphere. Concerts offer a wide variety of genres and styles, providing something for every musical taste. They're not just entertainment; they're a celebration of music and a way to experience art in its most dynamic form."
                ),
                Category(
                    id = "theater_shows",
                    name = "Theater Shows",
                    description = "Watch engaging theater performances",
                    descriptionLong = "Watching theater shows is a cultural experience that combines storytelling, acting, music, and stagecraft. Whether it’s a play, a musical, or an experimental performance, theater immerses you in stories that can be thought-provoking, entertaining, or emotionally moving. The live aspect of theater adds a unique element, as no two performances are exactly the same. Attending theater shows is a way to support the arts and enjoy a sophisticated form of entertainment."
                ),
                Category(
                    id = "museum_visits",
                    name = "Museum Visits",
                    description = "Explore historical and art museums",
                    descriptionLong = "Exploring museums offers an educational and cultural experience, whether you're interested in art, history, science, or specific themes. Museums house collections that tell stories, showcase creativity, and preserve history. They're spaces where you can learn, get inspired, and appreciate human achievements and natural wonders. From interactive exhibits to guided tours, museum visits are intellectually enriching activities suitable for all ages."
                ),
                Category(
                    id = "amusement_parks",
                    name = "Amusement Parks",
                    description = "Enjoy various rides and attractions",
                    descriptionLong = "Visiting amusement parks is a fun-filled activity that offers excitement and entertainment for people of all ages. With various rides, attractions, and shows, amusement parks provide a day of adventure and enjoyment. From thrilling roller coasters to family-friendly rides, there's something to suit every thrill level. Amusement parks are also great for group outings, offering a playful escape from the everyday routine."
                )
            )
        ),
        Category(
            id = "fitness_wellness",
            name = "Fitness and Wellness",
            categories = listOf(
                Category(
                    id = "yoga",
                    name = "Yoga",
                    description = "Practice yoga for physical and mental health",
                    descriptionLong = "Practicing yoga is a holistic activity that benefits both physical and mental health. It combines physical postures, breathing exercises, and meditation to promote flexibility, strength, and relaxation. Yoga is adaptable to all fitness levels and can be practiced in various settings, from studios to outdoor spaces. It's not just a form of exercise; it's a path to balance, mindfulness, and inner peace."
                ),
                Category(
                    id = "running",
                    name = "Running",
                    description = "Jogging or running for fitness",
                    descriptionLong = "Running is a simple and effective way to improve cardiovascular health and overall fitness. Whether it's jogging in the park, sprinting on a track, or participating in marathons, running caters to various levels of intensity and skill. It's a flexible activity that requires minimal equipment and can be done almost anywhere. Running is as much about mental endurance as it is about physical stamina, making it a rewarding challenge."
                ),
                Category(
                    id = "gym_workouts",
                    name = "Gym Workouts",
                    description = "Exercise at the gym",
                    descriptionLong = "Exercising at the gym offers a structured environment for fitness and health. With access to various equipment and classes, gym workouts can be tailored to individual goals, whether it's building strength, losing weight, or improving overall fitness. Gyms provide a motivating atmosphere, professional guidance, and a community of like-minded individuals. Regular gym workouts contribute to physical well-being and can be a powerful tool for personal transformation."
                ),
                Category(
                    id = "meditation",
                    name = "Meditation",
                    description = "Mindfulness and meditation practices",
                    descriptionLong = "Engaging in meditation and mindfulness practices is a way to achieve mental clarity, reduce stress, and enhance emotional well-being. Meditation involves focusing the mind and being present in the moment, often leading to a deeper understanding of oneself and a sense of inner peace. It can be practiced in various forms and settings, making it accessible to everyone. Regular meditation contributes to better mental health and a more balanced life."
                ),
                Category(
                    id = "dance_classes",
                    name = "Dance Classes",
                    description = "Learn various dance styles",
                    descriptionLong = "Taking dance classes is an enjoyable way to learn new skills, express yourself, and stay fit. Dance encompasses a wide range of styles, from ballet and contemporary to hip-hop and salsa, each offering a unique experience. Dance classes provide a social and supportive environment to learn and improve, regardless of age or skill level. It's not just about the steps or routines; dance is a form of artistic expression and a celebration of movement and rhythm."
                )
            )
        ),
        Category(
            id = "games_recreation",
            name = "Games and Recreation",
            categories = listOf(
                Category(
                    id = "board_games",
                    name = "Board Games",
                    description = "Play a variety of board games",
                    descriptionLong = "Playing board games is a fun and interactive way to spend time with friends and family. It offers a range of games suitable for all ages and interests, from strategy and adventure to trivia and family classics. Board games encourage critical thinking, creativity, and social interaction. They provide an excellent opportunity to disconnect from digital distractions and engage in face-to-face play, making for memorable and enjoyable moments."
                ),
                Category(
                    id = "video_gaming",
                    name = "Video Gaming",
                    description = "Engage in different video games",
                    descriptionLong = "Engaging in video games is a popular form of entertainment that spans a variety of genres, from action and adventure to strategy and sports. Video gaming can be a solo or social activity, with options for online multiplayer experiences. It offers immersive storytelling, challenging gameplay, and the opportunity to explore virtual worlds. Gaming can improve hand-eye coordination, problem-solving skills, and strategic thinking."
                ),
                Category(
                    id = "escape_rooms",
                    name = "Escape Rooms",
                    description = "Solve puzzles in escape rooms",
                    descriptionLong = "Escape rooms offer an immersive adventure where participants solve puzzles and find clues to \"escape\" from a themed room within a set time limit. These interactive experiences require teamwork, creativity, and problem-solving skills, making them ideal for group activities. Escape rooms come in various themes and difficulty levels, providing a thrilling and brain-teasing experience that's both challenging and entertaining."
                ),
                Category(
                    id = "trivia_nights",
                    name = "Trivia Nights",
                    description = "Participate in trivia contests",
                    descriptionLong = "Participating in trivia contests during trivia nights is a fun way to test your knowledge on various topics in a social setting. Typically held in bars, cafes, or community centers, these events foster a competitive yet friendly atmosphere. Teams or individuals answer questions on topics ranging from general knowledge to specific themes. Trivia nights are great for socializing, learning, and enjoying a bit of friendly competition."
                ),
                Category(
                    id = "sports_leagues",
                    name = "Sports Leagues",
                    description = "Join local sports leagues",
                    descriptionLong = "Joining local sports leagues is a great way to stay active, enjoy competitive play, and be part of a community. Whether it's soccer, basketball, baseball, or any other sport, local leagues cater to various skill levels and ages. Participating in sports leagues enhances physical fitness, teamwork skills, and provides an opportunity to meet new people and engage in community events."
                )
            )
        )
//        Category(
//            id = "learning_education",
//            name = "Learning and Education",
//            categories = listOf(
//                Category(
//                    id = "language_learning",
//                    name = "Language Learning",
//                    description = "Learn new languages"
//                ),
//                Category(
//                    id = "cooking_classes",
//                    name = "Cooking Classes",
//                    description = "Take cooking lessons"
//                ),
//                Category(
//                    id = "music_lessons",
//                    name = "Music Lessons",
//                    description = "Learn to play musical instruments"
//                ),
//                Category(
//                    id = "online_courses",
//                    name = "Online Courses",
//                    description = "Enroll in various online courses"
//                ),
//                Category(
//                    id = "workshops",
//                    name = "Workshops",
//                    description = "Attend educational workshops"
//                )
//            )
//        ),
//        Category(
//            id = "social_networking",
//            name = "Social and Networking",
//            categories = listOf(
//                Category(
//                    id = "meetup_events",
//                    name = "Meetup Events",
//                    description = "Join social gatherings and meetups"
//                ),
//                Category(
//                    id = "community_service",
//                    name = "Community Service",
//                    description = "Engage in community service activities"
//                ),
//                Category(
//                    id = "networking_events",
//                    name = "Networking Events",
//                    description = "Participate in professional networking events"
//                ),
//                Category(
//                    id = "group_hiking",
//                    name = "Group Hiking",
//                    description = "Go on group hiking adventures"
//                ),
//                Category(
//                    id = "book_clubs",
//                    name = "Book Clubs",
//                    description = "Join book discussion groups"
//                )
//            )
//        ),
//        Category(
//            id = "travel_tourism",
//            name = "Travel and Tourism",
//            categories = listOf(
//                Category(
//                    id = "city_tours",
//                    name = "City Tours",
//                    description = "Explore major cities and their attractions"
//                ),
//                Category(
//                    id = "beach_holidays",
//                    name = "Beach Holidays",
//                    description = "Relax and enjoy activities at the beach"
//                ),
//                Category(
//                    id = "road_trips",
//                    name = "Road Trips",
//                    description = "Plan and embark on road trips"
//                ),
//                Category(
//                    id = "cruises",
//                    name = "Cruises",
//                    description = "Enjoy sea travel on cruises"
//                ),
//                Category(
//                    id = "cultural_tours",
//                    name = "Cultural Tours",
//                    description = "Participate in tours focused on cultural exploration"
//                )
//            )
//        ),
//        Category(
//            id = "diy_home_improvement",
//            name = "DIY and Home Improvement",
//            categories = listOf(
//                Category(
//                    id = "home_decorating",
//                    name = "Home Decorating",
//                    description = "Projects for enhancing home aesthetics"
//                ),
//                Category(
//                    id = "furniture_building",
//                    name = "Furniture Building",
//                    description = "DIY building or refurbishing furniture"
//                ),
//                Category(
//                    id = "gardening",
//                    name = "Gardening",
//                    description = "Cultivating and maintaining gardens"
//                ),
//                Category(
//                    id = "home_repair",
//                    name = "Home Repair",
//                    description = "DIY home maintenance and repair projects"
//                ),
//                Category(
//                    id = "landscaping",
//                    name = "Landscaping",
//                    description = "Designing and organizing garden landscapes"
//                )
//            )
//        ),
//        Category(
//            id = "fashion_beauty",
//            name = "Fashion and Beauty",
//            categories = listOf(
//                Category(
//                    id = "fashion_design",
//                    name = "Fashion Design",
//                    description = "Designing and creating clothing"
//                ),
//                Category(
//                    id = "makeup_tutorials",
//                    name = "Makeup Tutorials",
//                    description = "Learning and applying makeup techniques"
//                ),
//                Category(
//                    id = "hair_styling",
//                    name = "Hair Styling",
//                    description = "Experimenting with different hairstyles"
//                ),
//                Category(
//                    id = "beauty_workshops",
//                    name = "Beauty Workshops",
//                    description = "Participating in beauty and skincare workshops"
//                ),
//                Category(
//                    id = "personal_styling",
//                    name = "Personal Styling",
//                    description = "Developing personal fashion style"
//                )
//            )
//        ),
//        Category(
//            id = "technology_gadgets",
//            name = "Technology and Gadgets",
//            categories = listOf(
//                Category(
//                    id = "tech_exploration",
//                    name = "Tech Exploration",
//                    description = "Discovering new technologies"
//                ),
//                Category(
//                    id = "gadget_reviews",
//                    name = "Gadget Reviews",
//                    description = "Reviewing and testing new gadgets"
//                ),
//                Category(
//                    id = "coding_projects",
//                    name = "Coding Projects",
//                    description = "Engaging in software development projects"
//                ),
//                Category(
//                    id = "gaming_tournaments",
//                    name = "Gaming Tournaments",
//                    description = "Participating in competitive gaming events"
//                ),
//                Category(
//                    id = "tech_workshops",
//                    name = "Tech Workshops",
//                    description = "Attending workshops on emerging technologies"
//                )
//            )
//        ),
//        Category(
//            id = "outdoor_activities",
//            name = "Outdoor Activities",
//            categories = listOf(
//                Category(
//                    id = "team_sports",
//                    name = "Team Sports",
//                    description = "Participating in group sports like soccer, basketball"
//                ),
//                Category(
//                    id = "individual_sports",
//                    name = "Individual Sports",
//                    description = "Engaging in individual sports like tennis, golf"
//                ),
//                Category(
//                    id = "extreme_sports",
//                    name = "Extreme Sports",
//                    description = "Adrenaline-pumping activities like bungee jumping, skydiving"
//                )
//            )
//        ),
//        Category(
//            id = "mindfulness_spirituality",
//            name = "Mindfulness and Spirituality",
//            categories = listOf(
//                Category(
//                    id = "meditation_retreats",
//                    name = "Meditation Retreats",
//                    description = "Attending retreats focused on meditation and mindfulness"
//                ),
//                Category(
//                    id = "spiritual_workshops",
//                    name = "Spiritual Workshops",
//                    description = "Workshops on spiritual practices and development"
//                ),
//                Category(
//                    id = "yoga_practices",
//                    name = "Yoga Practices",
//                    description = "Participating in different styles of yoga"
//                )
//            )
//        ),
//        Category(
//            id = "music_performing_arts",
//            name = "Music and Performing Arts",
//            categories = listOf(
//                Category(
//                    id = "instrument_playing",
//                    name = "Instrument Playing",
//                    description = "Learning and playing musical instruments"
//                ),
//                Category(
//                    id = "singing",
//                    name = "Singing",
//                    description = "Vocal performance and singing lessons"
//                ),
//                Category(
//                    id = "dance_performance",
//                    name = "Dance Performance",
//                    description = "Participating in dance classes and performances"
//                )
//            )
//        ),
//        Category(
//            id = "personal_development",
//            name = "Personal Development",
//            categories = listOf(
//                Category(
//                    id = "self_improvement_seminars",
//                    name = "Self-Improvement Seminars",
//                    description = "Attending seminars for personal growth and development"
//                ),
//                Category(
//                    id = "public_speaking_workshops",
//                    name = "Public Speaking Workshops",
//                    description = "Workshops to improve public speaking and presentation skills"
//                ),
//                Category(
//                    id = "career_development",
//                    name = "Career Development",
//                    description = "Courses and advice for career advancement"
//                )
//            )
//        ),
//        Category(
//            id = "hobbies_crafts",
//            name = "Hobbies and Crafts",
//            categories = listOf(
//                Category(
//                    id = "collectibles",
//                    name = "Collectibles",
//                    description = "Collecting various items such as stamps, coins"
//                ),
//                Category(
//                    id = "artistic_hobbies",
//                    name = "Artistic Hobbies",
//                    description = "Engaging in artistic hobbies like drawing, painting"
//                )
//            )
//        ),
//        Category(
//            id = "pet_animal_care",
//            name = "Pet and Animal Care",
//            categories = listOf(
//                Category(
//                    id = "dog_walking",
//                    name = "Dog Walking",
//                    description = "Walking and exercising pets"
//                ),
//                Category(
//                    id = "wildlife_interaction",
//                    name = "Wildlife Interaction",
//                    description = "Interacting with and caring for wildlife"
//                )
//            )
//        ),
//        Category(
//            id = "family_kids_activities",
//            name = "Family and Kids Activities",
//            categories = listOf(
//                Category(
//                    id = "educational_fun",
//                    name = "Educational Fun",
//                    description = "Activities that are fun and educational for children"
//                ),
//                Category(
//                    id = "family_outings",
//                    name = "Family Outings",
//                    description = "Outings suitable for the whole family"
//                )
//            )
//        ),
//        Category(
//            id = "environmental_conservation",
//            name = "Environmental and Nature Conservation",
//            categories = listOf(
//                Category(
//                    id = "eco_friendly_practices",
//                    name = "Eco-Friendly Practices",
//                    description = "Practices that promote sustainability and protect the environment"
//                ),
//                Category(
//                    id = "wildlife_conservation",
//                    name = "Wildlife Conservation",
//                    description = "Activities related to the conservation of wildlife and natural habitats"
//                )
//            )
//        ),
//        Category(
//            id = "history_culture",
//            name = "History and Culture",
//            categories = listOf(
//                Category(
//                    id = "historical_exploration",
//                    name = "Historical Exploration",
//                    description = "Exploring historical sites and learning about history"
//                ),
//                Category(
//                    id = "cultural_immersion",
//                    name = "Cultural Immersion",
//                    description = "Immersing in different cultural experiences"
//                )
//            )
//        ),
//        Category(
//            id = "gardening_horticulture",
//            name = "Gardening and Horticulture",
//            categories = listOf(
//                Category(
//                    id = "urban_gardening",
//                    name = "Urban Gardening",
//                    description = "Gardening in urban settings"
//                ),
//                Category(
//                    id = "landscape_design",
//                    name = "Landscape Design",
//                    description = "Designing and maintaining landscapes"
//                )
//            )
//        ),
//        Category(
//            id = "automotive_mechanics",
//            name = "Automotive and Mechanics",
//            categories = listOf(
//                Category(
//                    id = "car_enthusiast_activities",
//                    name = "Car Enthusiast Activities",
//                    description = "Activities for car enthusiasts, like car shows"
//                ),
//                Category(
//                    id = "mechanical_workshops",
//                    name = "Mechanical Workshops",
//                    description = "Workshops on vehicle maintenance and mechanics"
//                )
//            )
//        ),
//        Category(
//            id = "science_technology",
//            name = "Science and Technology",
//            categories = listOf(
//                Category(
//                    id = "scientific_exploration",
//                    name = "Scientific Exploration",
//                    description = "Exploring various scientific fields"
//                ),
//                Category(
//                    id = "technological_innovation",
//                    name = "Technological Innovation",
//                    description = "Innovations and advancements in technology"
//                )
//            )
//        ),
//        Category(
//            id = "literature_writing",
//            name = "Literature and Writing",
//            categories = listOf(
//                Category(
//                    id = "book_clubs",
//                    name = "Book Clubs",
//                    description = "Joining book discussion groups"
//                ),
//                Category(
//                    id = "writing_poetry",
//                    name = "Writing Poetry",
//                    description = "Composing and sharing poetry"
//                )
//            )
//        ),
//        Category(
//            id = "health_nutrition",
//            name = "Health and Nutrition",
//            categories = listOf(
//                Category(
//                    id = "dietary_wellness",
//                    name = "Dietary Wellness",
//                    description = "Learning about nutrition and healthy eating"
//                ),
//                Category(
//                    id = "physical_health",
//                    name = "Physical Health",
//                    description = "Activities focused on maintaining and improving physical health"
//                )
//            )
//        ),
//        Category(
//            id = "water_activities",
//            name = "Water Activities",
//            categories = listOf(
//                Category(
//                    id = "sailing",
//                    name = "Sailing",
//                    description = "Exploring the waters through sailing"
//                ),
//                Category(
//                    id = "fishing",
//                    name = "Fishing",
//                    description = "Engaging in fishing activities in various settings"
//                )
//            )
//        ),
//        Category(
//            id = "volunteer_community_service",
//            name = "Volunteer and Community Service",
//            categories = listOf(
//                Category(
//                    id = "local_charity_work",
//                    name = "Local Charity Work",
//                    description = "Participating in community charity events"
//                ),
//                Category(
//                    id = "community_building_projects",
//                    name = "Community Building Projects",
//                    description = "Contributing to local community development projects"
//                )
//            )
//        ),
//        Category(
//            id = "photography_videography",
//            name = "Photography and Videography",
//            categories = listOf(
//                Category(
//                    id = "photo_shoots",
//                    name = "Photo Shoots",
//                    description = "Engaging in professional photography sessions"
//                ),
//                Category(
//                    id = "video_production",
//                    name = "Video Production",
//                    description = "Creating and editing videos"
//                )
//            )
//        ),
//        Category(
//            id = "travel_adventure_sports",
//            name = "Travel and Adventure Sports",
//            categories = listOf(
//                Category(
//                    id = "paragliding",
//                    name = "Paragliding",
//                    description = "Experiencing the thrill of paragliding"
//                ),
//                Category(
//                    id = "mountain_biking",
//                    name = "Mountain Biking",
//                    description = "Off-road biking on mountain trails"
//                )
//            )
//        ),
//        Category(
//            id = "performing_arts",
//            name = "Performing Arts",
//            categories = listOf(
//                Category(
//                    id = "acting_workshops",
//                    name = "Acting Workshops",
//                    description = "Participating in acting and drama workshops"
//                ),
//                Category(
//                    id = "ballet",
//                    name = "Ballet",
//                    description = "Attending or participating in ballet performances"
//                )
//            )
//        ),
//        Category(
//            id = "language_communication",
//            name = "Language and Communication",
//            categories = listOf(
//                Category(
//                    id = "language_learning",
//                    name = "Language Learning",
//                    description = "Learning new languages"
//                ),
//                Category(
//                    id = "debate_clubs",
//                    name = "Debate Clubs",
//                    description = "Participating in debate clubs and competitions"
//                )
//            )
//        ),
//        Category(
//            id = "collecting_antiquities",
//            name = "Collecting and Antiquities",
//            categories = listOf(
//                Category(
//                    id = "coin_collecting",
//                    name = "Coin Collecting",
//                    description = "Collecting historical and rare coins"
//                ),
//                Category(
//                    id = "antique_hunting",
//                    name = "Antique Hunting",
//                    description = "Searching for and collecting antiques"
//                )
//            )
//        ),
//        Category(
//            id = "astronomy_space",
//            name = "Astronomy and Space Exploration",
//            categories = listOf(
//                Category(
//                    id = "stargazing",
//                    name = "Stargazing",
//                    description = "Observing celestial objects in the night sky"
//                ),
//                Category(
//                    id = "model_rocketry",
//                    name = "Model Rocketry",
//                    description = "Building and launching model rockets"
//                )
//            )
//        ),
//        Category(
//            id = "marine_aquatic_life",
//            name = "Marine and Aquatic Life",
//            categories = listOf(
//                Category(
//                    id = "aquarium_visits",
//                    name = "Aquarium Visits",
//                    description = "Visiting aquariums to learn about marine life"
//                ),
//                Category(
//                    id = "marine_biology_workshops",
//                    name = "Marine Biology Workshops",
//                    description = "Educational workshops on marine biology"
//                )
//            )
//        ),
//        Category(
//            id = "fashion_design_tailoring",
//            name = "Fashion Design and Tailoring",
//            categories = listOf(
//                Category(
//                    id = "custom_fashion_creation",
//                    name = "Custom Fashion Creation",
//                    description = "Designing and creating custom fashion items"
//                ),
//                Category(
//                    id = "textile_arts",
//                    name = "Textile Arts",
//                    description = "Working with textiles to create artistic designs"
//                )
//            )
//        ),
//        Category(
//            id = "music_production_djing",
//            name = "Music Production and DJing",
//            categories = listOf(
//                Category(
//                    id = "music_mixing",
//                    name = "Music Mixing",
//                    description = "Mixing and producing music tracks"
//                ),
//                Category(
//                    id = "dj_workshops",
//                    name = "DJ Workshops",
//                    description = "Learning DJ skills in workshops"
//                )
//            )
//        ),
//        Category(
//            id = "extreme_sports",
//            name = "Extreme Sports",
//            categories = listOf(
//                Category(
//                    id = "skydiving",
//                    name = "Skydiving",
//                    description = "Experiencing the thrill of skydiving"
//                ),
//                Category(
//                    id = "skateboarding",
//                    name = "Skateboarding",
//                    description = "Skateboarding in parks and urban settings"
//                )
//            )
//        ),
//        Category(
//            id = "urban_exploration_street_art",
//            name = "Urban Exploration and Street Art",
//            categories = listOf(
//                Category(
//                    id = "graffiti_art_tours",
//                    name = "Graffiti Art Tours",
//                    description = "Exploring urban street art"
//                ),
//                Category(
//                    id = "abandoned_places_exploration",
//                    name = "Abandoned Places Exploration",
//                    description = "Discovering and exploring abandoned urban locations"
//                )
//            )
//        ),
//        Category(
//            id = "digital_arts_media",
//            name = "Digital Arts and Media",
//            categories = listOf(
//                Category(
//                    id = "graphic_design",
//                    name = "Graphic Design",
//                    description = "Creating digital art and designs"
//                ),
//                Category(
//                    id = "animation_workshops",
//                    name = "Animation Workshops",
//                    description = "Learning animation techniques and tools"
//                )
//            )
//        ),
//        Category(
//            id = "magic_illusion",
//            name = "Magic and Illusion",
//            categories = listOf(
//                Category(
//                    id = "magic_performances",
//                    name = "Magic Performances",
//                    description = "Performing or attending magic shows"
//                ),
//                Category(
//                    id = "illusion_techniques",
//                    name = "Illusion Techniques",
//                    description = "Learning the art of illusion and magic tricks"
//                )
//            )
//        ),
//        Category(
//            id = "sustainability_eco_friendly",
//            name = "Sustainability and Eco-Friendly Living",
//            categories = listOf(
//                Category(
//                    id = "green_living_practices",
//                    name = "Green Living Practices",
//                    description = "Adopting sustainable and eco-friendly lifestyle practices"
//                ),
//                Category(
//                    id = "sustainable_development",
//                    name = "Sustainable Development",
//                    description = "Learning about sustainable development practices"
//                )
//            )
//        ),
//        Category(
//            id = "traditional_folk_arts",
//            name = "Traditional and Folk Arts",
//            categories = listOf(
//                Category(
//                    id = "folk_dancing",
//                    name = "Folk Dancing",
//                    description = "Participating in traditional folk dance"
//                ),
//                Category(
//                    id = "ethnic_arts",
//                    name = "Ethnic Arts",
//                    description = "Exploring and creating ethnic and traditional art forms"
//                )
//            )
//        ),
//        Category(
//            id = "aviation_flight",
//            name = "Aviation and Flight",
//            categories = listOf(
//                Category(
//                    id = "flight_simulation",
//                    name = "Flight Simulation",
//                    description = "Experiencing flying with flight simulators"
//                ),
//                Category(
//                    id = "gliding",
//                    name = "Gliding",
//                    description = "Flying in lightweight aircrafts"
//                )
//            )
//        ),
//        Category(
//            id = "esports_competitive_gaming",
//            name = "Esports and Competitive Gaming",
//            categories = listOf(
//                Category(
//                    id = "gaming_tournaments",
//                    name = "Gaming Tournaments",
//                    description = "Participating in or watching esports tournaments"
//                ),
//                Category(
//                    id = "streaming_games",
//                    name = "Streaming Games",
//                    description = "Live streaming video game sessions"
//                )
//            )
//        ),
//        Category(
//            id = "mind_games_puzzles",
//            name = "Mind Games and Puzzles",
//            categories = listOf(
//                Category(
//                    id = "sudoku",
//                    name = "Sudoku",
//                    description = "Challenging the mind with Sudoku puzzles"
//                ),
//                Category(
//                    id = "crossword_puzzles",
//                    name = "Crossword Puzzles",
//                    description = "Solving crossword puzzles"
//                )
//            )
//        ),
//        Category(
//            id = "health_alternative_medicine",
//            name = "Health and Alternative Medicine",
//            categories = listOf(
//                Category(
//                    id = "yoga_therapy",
//                    name = "Yoga Therapy",
//                    description = "Therapeutic yoga sessions"
//                ),
//                Category(
//                    id = "acupuncture",
//                    name = "Acupuncture",
//                    description = "Trying acupuncture for wellness"
//                )
//            )
//        ),
//        Category(
//            id = "cultural_studies_anthropology",
//            name = "Cultural Studies and Anthropology",
//            categories = listOf(
//                Category(
//                    id = "ethnic_music_dance",
//                    name = "Ethnic Music and Dance",
//                    description = "Exploring traditional music and dance forms"
//                ),
//                Category(
//                    id = "cultural_workshops",
//                    name = "Cultural Workshops",
//                    description = "Participating in workshops about different cultures"
//                )
//            )
//        ),
//        Category(
//            id = "financial_literacy_investment",
//            name = "Financial Literacy and Investment",
//            categories = listOf(
//                Category(
//                    id = "personal_finance_courses",
//                    name = "Personal Finance Courses",
//                    description = "Learning about personal finance management"
//                ),
//                Category(
//                    id = "investment_strategies",
//                    name = "Investment Strategies",
//                    description = "Understanding various investment strategies"
//                )
//            )
//        ),
//        Category(
//            id = "wilderness_survival_bushcraft",
//            name = "Wilderness Survival and Bushcraft",
//            categories = listOf(
//                Category(
//                    id = "survival_skills",
//                    name = "Survival Skills",
//                    description = "Learning skills to survive in the wilderness"
//                ),
//                Category(
//                    id = "outdoor_crafting",
//                    name = "Outdoor Crafting",
//                    description = "Creating tools and items in a natural setting"
//                )
//            )
//        ),
//        Category(
//            id = "robotics_automation",
//            name = "Robotics and Automation",
//            categories = listOf(
//                Category(
//                    id = "robotics_engineering",
//                    name = "Robotics Engineering",
//                    description = "Building and programming robots"
//                ),
//                Category(
//                    id = "home_automation_projects",
//                    name = "Home Automation Projects",
//                    description = "Creating home automation systems"
//                )
//            )
//        ),
//        Category(
//            id = "event_planning_hospitality",
//            name = "Event Planning and Hospitality",
//            categories = listOf(
//                Category(
//                    id = "wedding_planning",
//                    name = "Wedding Planning",
//                    description = "Organizing and planning wedding events"
//                ),
//                Category(
//                    id = "catering_hospitality",
//                    name = "Catering and Hospitality",
//                    description = "Learning about catering and hospitality services"
//                )
//            )
//        ),
//        Category(
//            id = "digital_marketing_social_media",
//            name = "Digital Marketing and Social Media",
//            categories = listOf(
//                Category(
//                    id = "seo_workshops",
//                    name = "SEO Workshops",
//                    description = "Workshops on search engine optimization techniques"
//                ),
//                Category(
//                    id = "social_media_marketing",
//                    name = "Social Media Marketing",
//                    description = "Learning strategies for marketing on social media platforms"
//                )
//            )
//        ),
//        Category(
//            id = "public_service_governance",
//            name = "Public Service and Governance",
//            categories = listOf(
//                Category(
//                    id = "community_leadership",
//                    name = "Community Leadership",
//                    description = "Engaging in community leadership and development"
//                ),
//                Category(
//                    id = "public_policy",
//                    name = "Public Policy",
//                    description = "Learning about public policy and governance"
//                )
//            )
//        ),
//        Category(
//            id = "interior_design_decoration",
//            name = "Interior Design and Decoration",
//            categories = listOf(
//                Category(
//                    id = "home_styling",
//                    name = "Home Styling",
//                    description = "Styling and decorating interiors"
//                ),
//                Category(
//                    id = "decorative_arts",
//                    name = "Decorative Arts",
//                    description = "Creating art for home decor"
//                )
//            )
//        ),
//        Category(
//            id = "aeronautics_model_aircraft",
//            name = "Aeronautics and Model Aircraft",
//            categories = listOf(
//                Category(
//                    id = "model_airplane_building",
//                    name = "Model Airplane Building",
//                    description = "Building and flying model airplanes"
//                ),
//                Category(
//                    id = "kite_making_flying",
//                    name = "Kite Making and Flying",
//                    description = "Crafting and flying kites"
//                )
//            )
//        ),
//        Category(
//            id = "genealogy_family_history",
//            name = "Genealogy and Family History",
//            categories = listOf(
//                Category(
//                    id = "ancestry_research",
//                    name = "Ancestry Research",
//                    description = "Researching family history and genealogy"
//                ),
//                Category(
//                    id = "family_tree_creation",
//                    name = "Family Tree Creation",
//                    description = "Creating detailed family trees"
//                )
//            )
//        ),
//        Category(
//            id = "nautical_skills_boating",
//            name = "Nautical Skills and Boating",
//            categories = listOf(
//                Category(
//                    id = "sailing_classes",
//                    name = "Sailing Classes",
//                    description = "Learning how to sail"
//                ),
//                Category(
//                    id = "canoeing",
//                    name = "Canoeing",
//                    description = "Canoeing in rivers, lakes, or seas"
//                )
//            )
//        ),
//        Category(
//            id = "philanthropy_charity_work",
//            name = "Philanthropy and Charity Work",
//            categories = listOf(
//                Category(
//                    id = "fundraising_events",
//                    name = "Fundraising Events",
//                    description = "Organizing and participating in events to raise funds for causes"
//                ),
//                Category(
//                    id = "charity_auctions",
//                    name = "Charity Auctions",
//                    description = "Participating in auctions to raise money for charitable organizations"
//                )
//            )
//        ),
//        Category(
//            id = "radio_podcasting",
//            name = "Radio and Podcasting",
//            categories = listOf(
//                Category(
//                    id = "podcast_creation",
//                    name = "Podcast Creation",
//                    description = "Creating and hosting podcasts"
//                ),
//                Category(
//                    id = "amateur_radio",
//                    name = "Amateur Radio",
//                    description = "Engaging in amateur radio broadcasting"
//                )
//            )
//        )
    )
}
