package ui.theme

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.PaletteColor
import mui.material.styles.createTheme

object AppTheme {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light
                primary = jso<PaletteColor> {
                    main = primaryColor
                }
            }
        }
    )
    val Dark = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.dark
                primary = jso<PaletteColor> {
                    main = primaryColor
                }
            }
        }
    )
}
