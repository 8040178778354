package ui.component

import mui.material.Box
import mui.system.sx
import react.FC
import react.PropsWithChildren
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.vh

val CenteredBox = FC<PropsWithChildren> { props ->
    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            minHeight = 100.vh
        }
        +props.children
    }
}
