package ui.component

import mui.material.Typography
import react.FC
import react.Props

external interface EmptyViewProps : Props {
    var message: String
}

val EmptyView = FC<EmptyViewProps> { props ->
    CenteredBox {
        Typography {
            +props.message
        }
    }
}
