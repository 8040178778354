import csstype.sx
import react.FC
import react.Props
import react.create
import react.dom.client.createRoot
import react.router.RouterProvider
import web.cssom.pct
import web.dom.document
import web.html.HTML.div

// Good reference examples
// https://github.com/karakum-team/kotlin-mui-showcase
// https://kotlinlang.org/docs/js-react.html

fun main() {
    val root = document.createElement(div)
        .apply { sx { height = 100.pct } }
        .also { document.body.appendChild(it) }

    createRoot(root)
        .render(App.create())
}

private val App = FC<Props> {
    RouterProvider {
        router = appRouter
    }
}
