package ui.screen.categorylist

import com.corradodev.dothings.repo.CategoryRepo
import react.FC
import react.Props
import react.router.useParams
import react.useState
import ui.component.AppLayout
import ui.component.EmptyView
import ui.screen.component.CategoryList

val CategoryListScreen = FC<Props> {
    val categoryId = useParams()["categoryId"]
    val category by useState(categoryId?.run { CategoryRepo().getById(this) })
    AppLayout {
        title = category?.name ?: ""
        if (category?.categories == null || category?.categories?.size == 0) {
            EmptyView {
                message = "Category does not exist"
            }
        } else {
            CategoryList {
                categories = category?.categories!!
            }
        }
    }
}
