package ui.screen.contact

import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import ui.component.AppLayout
import ui.component.CenteredBox

val ContactScreen = FC<Props> {
    AppLayout {
        title = "Contact Us"
        CenteredBox {
            Stack {
                Typography {
                    variant = TypographyVariant.h6
                    +"Need to get in contact with us?  Please email"
                }
                Link {
                    href = "mailto:support@dothingsapp.com"
                    underline = LinkUnderline.always
                    Typography { +"support@dothingsapp.com" }
                }
            }
        }
    }
}
