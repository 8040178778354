package ui.screen.home

import com.corradodev.dothings.repo.CategoryRepo
import react.FC
import react.Props
import react.useState
import ui.component.AppLayout
import ui.screen.component.CategoryList

val HomeScreen = FC<Props> {
    val pageCategories by useState(CategoryRepo().getAll())
    AppLayout {
        title = "DoThings"
        CategoryList {
            categories = pageCategories
        }
    }
}
