package ui.screen.notfound

import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.router.useNavigate
import ui.component.AppLayout
import ui.component.CenteredBox

val NotFoundScreen = FC<Props> {
    val navigate = useNavigate()
    AppLayout {
        title = "Page Not Found"
        CenteredBox {
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    navigate.invoke("/test/terms")
                }
                +"Go Home"
            }
        }
    }
}
