package com.corradodev.dothings.repo

import com.corradodev.dothings.data.Category
import com.corradodev.dothings.util.CategoryUtil

class CategoryRepo {
    private val categoryMap = CategoryUtil.categories.associateBy({ it.id }, { it })

    fun getById(id: String): Category? {
        return categoryMap[id]
    }

    fun getActivityById(id: String): Category? {
        return CategoryUtil.categories.flatMap { it.categories }.find { it.id == id }
    }

    fun getActivityShuffled(): Category {
        return CategoryUtil.categories.flatMap { it.categories }.random()
    }

    fun getAll(): List<Category> {
        return CategoryUtil.categories
    }
}
