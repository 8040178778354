package ui.screen.component

import com.corradodev.dothings.data.Category
import kotlinx.browser.window
import mui.material.ListItemButton
import mui.material.ListItemText
import react.FC
import react.Props
import react.ReactNode
import react.router.useNavigate

external interface CategoryListProps : Props {
    var categories: List<Category>
}

val CategoryList = FC<CategoryListProps> { props ->
    val navigate = useNavigate()
    mui.material.List {
        props.categories.forEach { category ->
            ListItemButton {
                onClick = {
                    if (category.categories.isEmpty()) {
                        window.open("https://www.google.com/search?q=" + category.name)
                    } else {
                        navigate.invoke("category/${category.id}")
                    }
                }
                ListItemText {
                    primary = ReactNode(category.name)
                    if (category.description.isNotEmpty()) {
                        secondary = ReactNode(category.description)
                    }
                }
            }
        }
    }
}
